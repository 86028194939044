.mde-preview .mde-preview-content pre {
  background-color: inherit;
  font-family: inherit;
  font-size: inherit;
}

.mde-preview .mde-preview-content pre > code {
  font-family: inherit;
  font-size: inherit;
}
