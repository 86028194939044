.navbar-logo {
  height: 40px;
}

.logo-container {
  float: left;
  padding-top: 15px;
  margin-left: -50px;
  height: 40px;
}

@media (max-width: 768px) {
  .tab-row {
    display: none; /* Hide the horizontal menu on small screens */
  }
  .mobile-menu-trigger {
    display: block; /* Show hamburger menu on small screens */
  }
}

@media (min-width: 769px) {
  .mobile-menu-trigger {
    display: none; /* Hide the hamburger menu on large screens */
  }
}
