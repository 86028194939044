.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.ant-modal-confirm-title,
.ant-modal-confirm-content {
  color: white !important;
}

.react-mde .mde-preview .mde-preview-content p {
  white-space: pre-wrap;
}
