/* /components/OrganizationPage.css */

.org-container {
  width: 90%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.org-table {
  width: 100%;
  text-align: center;
}

.edit-profile-container {
  max-width: 400px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.add-policy-container {
  max-width: 400px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.loading {
  width: 100%;
  max-width: 400px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
}

.select-directory,
.create-health-records-btn,
.directory-upload-wrapper > .ant-upload-wrapper,
.directory-upload-wrapper > .ant-upload-wrapper > .ant-upload-select {
  width: 100% !important;
}

.custom-field-container {
  max-width: 500px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.api-key-container {
  max-width: 700px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.white-placeholder::placeholder {
  /* color: grey; */
  opacity: 1; /* Override any default opacity in browsers */
}

.white-label {
  color: white;
}
