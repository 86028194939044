.clinical-gpt-page {
  max-width: 800px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

.gpt-form-title {
  font-family: "Ubuntu", sans-serif;
}

.generated-letter {
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
}

.generated-letter .ant-typography {
  font-family: "Ubuntu", sans-serif;
}

.generated-letter pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.loading {
  width: 100%;
  max-width: 400px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
}

.no-border-radius-button {
  border-radius: 0;
}

.p-4 {
  padding: 10px;
}

.ant-modal {
  width: 80% !important;
}

.ant-select.custom-width {
  width: 25% !important;
}

.ant-table-thead > tr > th:nth-child(2),
.ant-table-tbody > tr > td:nth-child(2) {
  min-width: 90px !important;
}

.note {
  font-family: "Ubuntu", sans-serif;
  font-size: 12px;
}

.pl-0 {
  padding-left: 0px !important;
}

.white-label {
  color: white !important;
}

.blue-label {
  color: #1677ff !important;
}

.ant-switch.ant-switch-checked {
  background-color: #4096ff !important;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  width: 28px; /* Adjust as needed */
}

#tiff-inner-container > canvas {
  max-width: 80% !important;
}
