.task-card {
  width: 100% !important;

  .ant-card-head {
    border-bottom: none !important;
  }
}

.ant-card-head {
  padding-right: 5px !important;
  min-height: 32px !important;
  border-bottom: none !important;
}

.selected-task-card {
  width: 100% !important;
  border: 1px solid #e7008a !important;
}

.ant-card {
  border: none;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.ant-card-body {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
  padding-left: 24px !important;
}

.task-card-btn {
  background: none !important;
  border: none !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-8 {
  margin-right: 8px !important;
}
