/* /components/Usage.css */

.usage-container {
  width: 90%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.usage-table {
  width: 100%;
  text-align: center;
}

.loading {
  width: 100%;
  max-width: 400px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
}
