.form-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-row {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.form-row.equipment {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  margin-bottom: 4px;
}

.form-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-column.equipment {
  min-width: 200px;
  flex: 0 0 200px;
}

.form-column.checkbox {
  flex: 0 0 32px;
  min-width: 32px;
  margin-right: -4px;
}

.form-column.dropdown {
  min-width: 400px;
  margin-right: 4px;
  width: 600px;
}

.form-column.input {
  flex: 0 0 140px;
  min-width: 140px;
  width: 140px;
}

.form-column.dropdown .ant-select {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 8px;
}

@media (max-width: 1200px) {
  .form-row.equipment {
    flex-wrap: wrap;
  }

  .form-column.dropdown {
    flex: 1 1 calc(100% - 200px);
  }

  .form-column.input {
    flex: 1 1 calc(50% - 200px);
    min-width: 120px;
  }
}

@media (max-width: 768px) {
  .form-column.dropdown,
  .form-column.input {
    flex: 1 1 100%;
  }
}

.form-container h2 {
  margin-bottom: 12px;
}

.form-container h3 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  display: flex;
  align-items: center;
  height: 32px;
}

.ant-form-item-label {
  padding-bottom: 4px;
}

.ant-select-selector {
  height: 32px;
}

.ant-select-dropdown {
  min-width: 200px !important;
  max-width: 100vw !important;
}

.ant-select-item-option-content {
  white-space: normal;
  word-wrap: break-word;
}

.ant-select-item {
  padding: 8px 12px;
  min-height: 32px;
}

input#equipment_water_chamber_product {
  display: block; /* Or display: flex */
  width: 100%; /* Optional, might be redundant with flex: 1 */
  flex: 1; /* Or flex-grow: 1 */
}

.form-column.input .ant-form-item,
.form-column.dropdown .ant-form-item {
  width: 100%;
}

.form-column.input .ant-input,
.form-column.dropdown .ant-select {
  width: 100%;
}
