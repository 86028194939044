.interactive-json-viewer {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.button-container {
  margin-top: 10px;
}

.button-container button {
  margin-right: 10px;
}

.copy-btn {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #40a9ff;
}

.save-updated {
  background-color: #52c41a;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.save-updated:hover {
  background-color: #73d13d;
}

.generated-letter {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
}
