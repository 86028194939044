body {
  background-color: #f3f4f6;
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.loginPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.logoContainer {
  margin-bottom: 20px;
}

.logoStyle {
  width: 150px;
  height: auto;
}

.loginContent {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  color: #666;
  margin-bottom: 20px;
}

.loginButton {
  width: 100%;
  background: linear-gradient(90deg, #e7008a, #d16823, #8a07b1, #2c52ef);
  border: none;
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.loginButton:hover {
  opacity: 0.9;
}

.forgotPasswordLink {
  margin-top: 10px;
  display: inline-block;
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.forgotPasswordLink:hover {
  color: #0056b3;
}

.ant-modal {
  border-radius: 10px;
}

.ant-modal-header {
  border-bottom: none;
}

.ant-modal-footer {
  border-top: none;
}

input {
  margin-top: 10px;
}
