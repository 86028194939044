.ant-list-item {
  padding: 5px 0 !important;
}

.edit-profile-title {
  text-align: center !important;
}

.ant-btn-primary:disabled {
  background-color: gray !important;
  border-color: gray !important;
  color: darkgray !important;
}
